import React, { useEffect, useMemo, useRef, useState } from 'react';
import { KiloCheckout, KiloProduct } from '@core/checkout-web';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import { useSelector } from 'react-redux';
import { handleError as trackErrorSentry } from 'utils/error';
import { useQuizData } from 'utils/hooks';

import { getLocalisedProduct } from 'utils/localization';
import { Analytics } from 'apis/Analytics';

import OldPayments from './OldPayments';
import Payments from './Payments';

export const PAYMENT_METHOD = {
  stripe: 'kilo-payment-primer_payments',
  paypal: 'kilo-payment-paypal_direct',
};

export const KiloPayments = ({
  code,
  children,
  handleError,
  handleSuccess,
  initialProduct,
}) => {
  const checkoutRef = useRef(new KiloCheckout());
  const IS_STYLE_LOADED = useRef(false);

  const [selectedId, setSelectedId] = useState('');
  const [loading, setLoading] = useState(true);
  const urlConfigs = useSelector(state => state.payment?.gateway);
  const clientEmail = useSelector(state => state.user.user?.email);

  const data = useQuizData('payments');

  const paymentIds = useMemo(() => {
    const ids = Object.keys(urlConfigs?.prepare_urls ?? []).map(
      url => `kilo-payment-${url}`,
    );
    if (data?.arePaymentsReversed) {
      return [...ids].reverse();
    }
    return ids;
  }, [urlConfigs, data?.arePaymentsReversed]);

  const loadStyle = href =>
    new Promise(resolve => {
      if (IS_STYLE_LOADED?.current || !href) {
        resolve();
        return;
      }

      const element = document.createElement('link');
      const attributes = {
        id: 'kilo-style-' + btoa(href),
        rel: 'stylesheet',
        href,
      };

      each(attributes, (value, name) => (element[name] = value));

      element.onload = () => {
        IS_STYLE_LOADED.current = true;
        resolve();
      };

      document['head'].appendChild(element);
    });

  const load = async () => {
    if (urlConfigs?.style) {
      await loadStyle(urlConfigs.style);
    }

    createUi();
  };

  const onSuccess = data => {
    try {
      const localisedProduct = getLocalisedProduct(initialProduct);
      Analytics.trackPurchase(
        data?.order_id,
        localisedProduct?.currencyId,
        localisedProduct?.sku,
        localisedProduct?.name,
        localisedProduct?.finalPrice,
      );
      // purchase event for GA4
      Analytics.trackSuccessPurchase({
        purchase_type: 'initial',
        ecommerce: {
          email: clientEmail,
          transaction_id: data?.order_id,
          value: Number(localisedProduct?.finalPrice),
          currency: localisedProduct?.currencyId,
          items: [
            {
              item_name: localisedProduct?.name,
              item_id: localisedProduct?.sku,
              price: Number(localisedProduct?.finalPrice),
              item_category: 'initial',
              quantity: 1,
            },
          ],
        },
      });
      if (typeof handleSuccess === 'function') {
        handleSuccess(data);
      }
    } catch (e) {
      trackErrorSentry(e);
    }

    return false;
  };

  const onSubmit = data => {
    Analytics.trackPaymentSubmit(data.method, data.product);
    if (data?.payment_provider === 'paypal_direct') {
      return;
    }
    setLoading(true);
  };

  const onError = e => {
    setLoading(false);
    console.log(e);
    if (typeof handleError === 'function') {
      handleError(e);
    }
    return false;
  };

  const createUi = async () => {
    try {
      setLoading(true);
      const localisedProduct = getLocalisedProduct(initialProduct);
      const product = new KiloProduct(
        initialProduct.key,
        initialProduct.name,
        Number(localisedProduct.finalPrice),
      );

      console.log(product);

      if (urlConfigs.prepare_urls) {
        await Promise.all(
          Object.keys(urlConfigs.prepare_urls).map(async url => {
            await checkoutRef.current.create(urlConfigs.prepare_urls[url], {
              product,
              clientCode: code,
              selector: `#kilo-payment-${url}`,
              callbacks: {
                onError,
                onSubmit,
                onSuccess,
              },
            });
          }),
        );
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      load();
    }
  }, [code]);

  useEffect(() => {
    setSelectedId(paymentIds?.[0] ?? '');
  }, [paymentIds]);

  return (
    <>
      <div hidden={loading}>
        {location.search.includes('um1') ? (
          <Payments
            ids={paymentIds}
            selectedId={selectedId}
            onItemClick={setSelectedId}
          />
        ) : (
          <OldPayments
            ids={paymentIds}
            selectedId={selectedId}
            onItemClick={setSelectedId}
          />
        )}
      </div>
      {loading ? children : null}
    </>
  );
};

KiloPayments.propTypes = {
  children: PropTypes.any,
  code: PropTypes.string,
  handleError: PropTypes.func,
  handleSuccess: PropTypes.func,
  initialProduct: PropTypes.object,
};
