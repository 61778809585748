import { useLocation } from '@reach/router';
import { Analytics } from 'apis/Analytics';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { Question, QuizAnswer } from 'types/quiz';

const MIN_SCORE = 10;
const MAX_SCORE = 40;

export const useQuizData = (page: string) => {
  const [quizData, setQuizData] = useState<any>(null);
  const config = useSelector((state: AppState) => state.config);

  const getQuizData = useCallback(() => {
    const success = config?.[page];

    setQuizData(success);
  }, [config]);

  useEffect(() => {
    if (!config) {
      return;
    }
    getQuizData();
  }, [config]);

  return quizData;
};

export const useCheckoutData = () => {
  const isDiscount = !!(
    (location as { state?: { discount?: string } })?.state?.discount ||
    location?.pathname?.includes('/checkout-special')
  );

  const quizData = useQuizData(isDiscount ? 'checkoutSpecial' : 'checkout');

  return { data: quizData, isDiscount };
};

export const useQuizWeightResult = (
  minScore: number = MIN_SCORE,
  maxScore: number = MAX_SCORE,
) => {
  const quizQuestions = useSelector(
    (state: AppState) => state.funnel.questions,
  );
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  const quizQuestionsObj = useMemo(() => {
    const obj: Record<string, Question> = {};

    quizQuestions.forEach(question => {
      obj[question.key] = question;
    });

    return obj;
  }, [quizQuestions]);

  const weightSum = useMemo(
    () =>
      Object.keys(quizAnswers).reduce((sum: number, key: string) => {
        const answers = quizAnswers[key].split('|');
        const matchingQuestion = quizQuestionsObj[key] as Question;

        if (!matchingQuestion) return sum;

        answers.forEach((answer: string) => {
          matchingQuestion.options?.forEach(option => {
            if (option?.value?.toLowerCase() === answer?.toLowerCase()) {
              sum += parseInt(option.score) || 0;
            }
          });
        });

        return sum;
      }, 0),
    [quizAnswers, quizQuestionsObj],
  );

  const totalSum = useMemo(
    () =>
      quizQuestions.reduce((totalSum: number, question: unknown) => {
        // if no question options - returns current total sum
        if (!question) {
          return totalSum;
        }
        if (!question.options) {
          return totalSum;
        }
        // if multiple options - returns those options sum
        if (question.type === 'multiple') {
          const multipleQuestionSum = question.options.reduce(
            (sum: number, option: { score?: number }) =>
              sum + (option.score || 0),
            0,
          );

          return totalSum + multipleQuestionSum;
        }
        // if single option - returns highest option
        const highestSingleQuestion = Math.max(
          ...question.options.map(
            (option: { score: number }) => option.score || 0,
          ),
        );
        return highestSingleQuestion + totalSum;
      }, 0),
    [quizQuestions],
  );

  return {
    sum:
      weightSum < minScore
        ? minScore
        : weightSum > maxScore
        ? maxScore
        : weightSum,
    total:
      totalSum < minScore
        ? maxScore
        : totalSum > maxScore
        ? maxScore
        : totalSum,
  };
};

export const useQuizAnswers = () => {
  const answers: QuizAnswer = useSelector((s: AppState) => s.user.quiz_answers);
  return { answers };
};

export const usePageView = () => {
  const { pathname, search } = useLocation();
  const pageUrl = pathname + search;
  useEffect(() => {
    Analytics.logEvent({
      event: 'virtualPageview',
      pageUrl: pageUrl,
      pageTitle: pathname,
    });
  }, [pageUrl, pathname]);
};
